import { PLATFORM } from 'aurelia-pal';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';

export class Index extends RouterConfigBase {
	static inject = [EventAggregator, Security, Menus];

	constructor(ea, security, menus) {
        super(ea, security, menus);
	}

	configureRouter(config, router){
		config.map([
            {
				route: ['', '/:leadType'],
				moduleId: PLATFORM.moduleName('members/leads/lead-list'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
				settings: {
					title: 'agent-leads',
					description: 'agent-leads-description',
					canAddToMobileMenu: true
				},
			},
            {
				route: ['lead/:id', 'lead/:id/:view'],
				moduleId: PLATFORM.moduleName('members/leads/lead'),
				nav: false,
				membersOnly: true,
				adminOnly: false,
				settings: {
					title: 'agent-lead',
					description: 'agent-lead-description',
					canAddToMobileMenu: false
				},
			},
            {
				route: 'activity',
				moduleId: PLATFORM.moduleName('members/leads/activity-dashboard'),
				nav: false,
                settings: { title: 'lead-activity-dashboard', description: 'lead-activity-dashboard-description', canAddToMobileMenu: true },
			}
		]);

		this.router = router;
	}

	attached() {
		this._attached();
	}

	detached() {
		this._detached();
	}
}

import { PLATFORM } from 'aurelia-pal';
import { Leaderboards } from 'services/leaderboards';
import { I18n } from 'common/i18n';
import { LeaderboardEntryEditor } from './dialogs/leaderboard-entry-editor';
import { DialogService } from 'aurelia-dialog';
import { c } from 'common/common';
import { LineOfBusinessValueConverter } from 'resources/value-converters/line-of-business';
PLATFORM.moduleName('./dialogs/leaderboard-entry-editor');

export class Entries {
    static inject = [Leaderboards, I18n, DialogService, LineOfBusinessValueConverter];
    _leaderboards;
    _i18n;
    _dialogService;
    _lobValueConverter;
    code;
    _memberId;

    columns;
    gridData;
    emptyGridMessage;

	constructor(leaderboards, i18n, dialogService, lobValueConverter) {
		this._leaderboards = leaderboards;
        this._i18n = i18n;
        this._dialogService = dialogService;
        this._lobValueConverter = lobValueConverter;
        
        this._initializeGrid();
	}

    _initializeGrid() {
        this.columns = [];
        this.columns.push(c.FlexGrid.column('date', c.FlexGrid.sizes.small, this._i18n.tr('entry-date'), c.FlexGrid.types.date, { formatter: 'L' }));
        this.columns.push(c.FlexGrid.column('lineOfBusiness', c.FlexGrid.sizes.small, this._i18n.tr('line-of-business'), c.FlexGrid.types.string, {}));
        this.columns.push(c.FlexGrid.column('value', c.FlexGrid.sizes.small, this._i18n.tr('value'), c.FlexGrid.types.money, {}));
    }

	activate(params) {
        this.code = params.code;
        this._memberId = params.memberId;
    }

    attached() {
        this._load();
    }

    async _load() {
        try {
            this.loadingFlexgrid = true;
            this.data = await this._leaderboards.entries(this.code, this._memberId);
            let gridData = [];
            this.member = {
                id: this.data.memberId,
                firstName: this.data.firstName,
                lastName: this.data.lastName,
            };
            for (let entry of this.data.entries) {
                gridData.push({
                    highlight: false,
                    values: [
                        c.FlexGrid.cellData('id', entry.id),
                        c.FlexGrid.cellData('date', entry.entryDate),
                        c.FlexGrid.cellData('lineOfBusiness', this._lobValueConverter.toView(entry.lineOfBusiness)),
                        c.FlexGrid.cellData('value', entry.value),
                    ]
                });
            }
            this.gridData = gridData;
        } catch (err) {
            console.log(err);
        }
    }

    openSummary(id) {
        const entry = this.data.entries.find(x => x.id === id);
	    this._dialogService.open({ viewModel: LeaderboardEntryEditor, model: entry, ignoreTransitions: true }).then(response => {
	        if (response.wasCancelled) return;
	        this._load();
	    });
    }
}

import { Nylas } from 'services/nylas';
import { Router } from 'aurelia-router';

export class NylasCallback {
    static inject = [Nylas, Router];
    _nylas;
    _router;

    _notifyKey;
    _notifyType;
    _redirectTo;

    constructor(nylas, router) {
        this._nylas = nylas;
        this._router = router;
    }

    async activate(model) {
        try {
            this._from = model.from;
            await this._nylas.saveSettings(model.namespace_id, model.account_id, model.sid, model.access_token, model.provider, model.email_address);
            this._notifyKey = 'nylas-authentication-success';
            this._notifyType = 'success';
        } catch (err) {
            console.log(err);
            this._notifyKey = 'nylas-authentication-error';
            this._notifyType = 'error';
        }
    }

    attached() {
        let redirectTo = `#/members/my-profile?notifyKey=${this._notifyKey}&notifyType=${this._notifyType}`;
        if (this._from === 'task') redirectTo = `#/members/tasks?notifyKey=${this._notifyKey}&notifyType=${this._notifyType}`;
        this._router.navigate(redirectTo);
    }
}
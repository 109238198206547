import { PLATFORM } from 'aurelia-pal';
import { observable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogService } from 'aurelia-dialog';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Security } from 'common/security';
import { Calendar } from 'services/calendar';
import { Notifier } from 'common/ui';
import { Nylas } from 'services/nylas';
import { ConfirmDialog } from 'common/dialogs/confirm/confirm-dialog';
import { SchedulingPageEditor } from './scheduling-page-editor';
PLATFORM.moduleName('common/dialogs/confirm/confirm-dialog');
PLATFORM.moduleName('./scheduling-page-editor');

export class YourCalendarOnlineEditor {
    static inject = [EventAggregator, DialogService, DialogController, NewInstance.of(ValidationController), Calendar, Security, Notifier, Nylas];
    _ea;
    _dialogService;
    _calendar;
    _security;
    _notifier;
    _nylas;

    memberId = null;
    forMemberId;
    isActive = false;
    slug = '';

    @observable({ changeHandler: '_updatePreview' }) title;
    @observable({ changeHandler: '_updatePreview' }) headerColor;
    bioImageUrl;
    @observable({ changeHandler: '_updatePreview' }) bioTitle;
    @observable({ changeHandler: '_updatePreview' }) bio;
    @observable({ changeHandler: '_updatePreview' }) buttonText;
    @observable({ changeHandler: '_updatePreview' }) buttonColor;

    deleteKey = 'delete';

    schedulePages = [];
    selectedSchedulingPages = 0;

    schedulePageTypes = ['phone', 'online', 'other'];
    schedulePageDurations = ['10', '15', '30', '60', 'other'];

    previewUrl = 'about:blank';
    _handlers = [];

    constructor(ea, dialogService, dialogController, validationController, calendar, security, notifier, nylas) {
        this._ea = ea;
        this._dialogService = dialogService;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._calendar = calendar;
        this._security = security;
        this._notifier = notifier;
        this._nylas = nylas;

        ValidationRules
            .ensure('slug').required()
            .ensure('title').required()
            .ensure('bioTitle').required()
            .ensure('buttonText').required()
            .on(this);
    }

    activate(model) {
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this._schedulingPageReordered(), 0);
        }));

        this.memberId = model.memberId;
        this.forMemberId = this._security.isAuthenticatedMember(this.memberId) ? null : this.memberId;

        let uploadProfileImageTo = 'api/your-calendar-online/upload-profile-image';
        if (this.forMemberId) uploadProfileImageTo += `?memberId=${encodeURIComponent(this.forMemberId)}`;
        this.uploadProfileImageTo = uploadProfileImageTo;

        this.baseUrl = '';
        this.isActive = false;
        this.slug = '';
        this.title = '';
        this.bioImageUrl = '';
        this.bioTitle = '';
        this.bio = '';
        this.headerColor = '';
        this.buttonText = '';
        this.buttonColor = '';
        this.schedulePages = [];
        this.nylasSchedulingPages = [];
        this.nylasCalendars = [];

        if (model.yourCalendarOnline) {
            this.baseUrl = model.yourCalendarOnline.baseUrl;
            this.isActive = model.yourCalendarOnline.isActive;
            this.slug = model.yourCalendarOnline.slug;
            this.title = model.yourCalendarOnline.pageTitle;
            this.bioImageUrl = model.yourCalendarOnline.bioImageUrl;
            this.bioTitle = model.yourCalendarOnline.bioTitle;
            this.bio = model.yourCalendarOnline.bio;
            this.headerColor = model.yourCalendarOnline.headerColor;
            this.buttonText = model.yourCalendarOnline.buttonText;
            this.buttonColor = model.yourCalendarOnline.buttonColor;
            this.schedulePages = model.yourCalendarOnline.schedulePages;
            this.nylasSchedulingPages = model.yourCalendarOnline.nylasSchedulingPages;
            this.nylasSchedulingPages.sort((a, b) => a.order)
            this.nylasCalendars = model.yourCalendarOnline.nylasCalendars;
            this._initializeSchedulePages();
        }
        this.validationController.reset();
        this.errorKey = null;
        this._savedMetadata = JSON.parse(JSON.stringify(this.siteMetadata()));
        this._updatePreview();
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    @computedFrom('schedulePages')
    get showFullEditor() {
        return this.schedulePages && this.schedulePages.length > 0;
    }

    @computedFrom('schedulePages')
    get showOldEditor() {
        if (!this.schedulePages || !this.schedulePages.length) return false;
        const showOldEditor = this.schedulePages[0].nylasId ? false : true;
        return showOldEditor;
    }

    async reloadSchedulingPages() {
        try {
            const yco = await this._calendar.yourCalendarOnline(this.forMemberId);
            this.nylasSchedulingPages = yco.nylasSchedulingPages;
            this._initializeSchedulePages();
        } catch (err) {
            console.log(err);
        }
    }

    _initializeSchedulePages() {
        this.nylasSchedulingPages.forEach(nsp => {
            nsp.url = `${this._nylas.schedulePageBase}${nsp.slug}`;
            const sp = this.schedulePages.find(x => x.nylasId === nsp.nylasId);
            if (sp) {
                nsp.selected = sp.selected;
                nsp.index = sp.index;
                nsp.description = sp.description;
            } else {
                nsp.selected = false;
                nsp.index = 9999;
            }
            const cal = this.nylasCalendars.find(x => x.id === nsp.calendarId);
            if (cal) nsp.calendar = cal.name;
        });
        this.nylasSchedulingPages.sort((a, b) => a.index - b.index);
    }

    schedulingPageSelected(nylasSchedulingPage) {
        if (this.selectedSchedulingPages === 3 && !nylasSchedulingPage.selected) {
            this._notifier.info('your-calendar-online-max-pages-selected');
            return;
        }
        nylasSchedulingPage.selected = !nylasSchedulingPage.selected;
        this.selectedSchedulingPages = this.nylasSchedulingPages.filter(x => x.selected).length;
        this._updatePreview();
        return false;
    }

    _schedulingPageReordered() {
        this._updatePreview();
    }

    onDescriptionChanged() {
        this._updatePreview();
    }

    async saveSchedulingPages() {
        if (this.saving) return;
        try {
            this.saving = true;
            await this._calendar.saveSchedulingPages(this.nylasSchedulingPages, this.forMemberId);
            const yco = await this._calendar.yourCalendarOnline(this.forMemberId);
            this.schedulePages = yco.schedulePages;
            this._initializeSchedulePages();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    _updatePreview() {
        this.previewUrl = 'about:blank';
        if (!this.baseUrl || !this.slug || this._activating) return;
        window.setTimeout(() => {
            const previewMetadata = this.siteMetadata();
            const url = `${this.baseUrl}#${this.slug}?preview=${encodeURIComponent(JSON.stringify(previewMetadata))}&previewBuster=${new Date().getTime()}`;
            this.previewUrl = url;
        }, 250);
    }

    siteMetadata() {
        const schedulePages = [];
        let index = 1;
        this.nylasSchedulingPages.forEach(sp => {
            schedulePages.push({
                nylasId: sp.nylasId,
                description: sp.description,
                duration: sp.durationMinutes,
                index: index++,
                slug: sp.slug,
                title: sp.name,
                type: sp.location,
                selected: sp.selected,
            });
        });
        return {
            bio: this.bio,
            bioImageUrl: this.bioImageUrl,
            bioTitle: this.bioTitle,
            buttonColor: this.buttonColor,
            buttonText: this.buttonText,
            headerColor: this.headerColor,
            pageTitle: this.title,
            schedulePages: schedulePages,
        };
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.errorKey = null;
            this.saving = true;

            const selectedPages = this.nylasSchedulingPages.filter(x => x.selected);
            if (!selectedPages.length) {
                this._notifier.error('your-calendar-online-require-schedule-page');
                return;
            }

            const schedulePages = [];
            let index = 1;
            this.nylasSchedulingPages.forEach(sp => {
                const cloneSp = JSON.parse(JSON.stringify(sp));
                cloneSp.index = index++;
                cloneSp.nylasId = cloneSp.nylasId;
                cloneSp.title = cloneSp.name;
                cloneSp.type = cloneSp.location;
                cloneSp.duration = '' + cloneSp.durationMinutes;
                cloneSp.slug = cloneSp.slug;
                cloneSp.description = cloneSp.description;
                delete cloneSp.calendar;
                delete cloneSp.calendarId;
                delete cloneSp.durationMinutes;
                delete cloneSp.name;
                delete cloneSp.url;
                delete cloneSp.location;
                schedulePages.push(cloneSp);
            });

            const saveModel = {
                isActive: this.isActive,
                pageTitle: this.title,
                bioTitle: this.bioTitle,
                bio: this.bio,
                headerColor: this.headerColor,
                buttonText: this.buttonText,
                buttonColor: this.buttonColor,
                schedulePages: schedulePages,
            };

            await this._calendar.saveYourCalendarOnline(this.slug, saveModel, this.forMemberId);
            this._notifier.success('your-calendar-online-update-successful');
            const metadata = this.siteMetadata();
            this._savedMetadata = JSON.parse(JSON.stringify(metadata));
            this._updatePreview();
        } catch (err) {
            this._notifier.error(err);
            this.errorKey = err;
        } finally {
            this.saving = false;
        }
    }

    async bioImageUploaded() {
        try {
            const yco = await this._calendar.yourCalendarOnline(this.forMemberId);
            this.bioImageUrl = yco.bioImageUrl;
            this._updatePreview();
        } catch (err) {
            console.log(err);
        }
    }

    openNylasScheduleEditor() {
        this._nylas.editScheduler(this.forMemberId);
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteKey = 'delete';
    }

    async delete() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            const response = await this._calendar.deleteYourCalendarOnline(this.forMemberId);
            this.dialogController.ok();
        } catch(err) {
            this.resetDelete();
            this._notifier.generalError();
        } finally {
            this.deleting = false;
        }
    }

    openSchedulingPageEditor(nylasSchedulingPage) {
        const model = {
            nylasSchedulingPage,
            calendars: this.nylasCalendars,
        };
        this._dialogService.open({ viewModel: SchedulingPageEditor, model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled) return;
            await this.reloadSchedulingPages();
        });
    }

    close() {
        try {
            const currentMetadata = this.siteMetadata();
            if (JSON.stringify(currentMetadata) !== JSON.stringify(this._savedMetadata)) {
                const model = { key: 'profile:yco-save-changes', okButtonClass: 'btn-primary' };
                this._dialogService.open({ viewModel: ConfirmDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                    if (response.wasCancelled) {
                        this.dialogController.cancel();
                        return;
                    }
                    await this.save();
                    this.dialogController.cancel();
                });
            } else {
                this.dialogController.cancel();
            }
        } catch (err) {
            console.log(err);
        }
    }
}

import { inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { I18n } from 'common/i18n';
import { Leaderboards } from 'services/leaderboards';
import { Notifier } from 'common/ui';
import moment from 'moment';

@inject(DialogController, NewInstance.of(ValidationController), I18n, Leaderboards, Notifier)
export class LeaderboardEntryEditor {
    id;
    entryDate;
    value;
    lineOfBusiness;

    constructor(dialogController, validationController, i18n, leaderboards, notifier) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._i18n = i18n;
        this._leaderboards = leaderboards;
        this._notifier = notifier;

        this.deleteText = this._i18n.tr('delete');

        ValidationRules
            .ensure('entryDate').required()
            .ensure('value').required()
            .on(this);
    }

    activate(model) {
        this.id = model.id;
        this.entryDate = moment(model.entryDate).format('YYYY-MM-DD');
        this.value = model.value;
        this.lineOfBusiness = model.lineOfBusiness;
        this.validationController.reset();
    }

    async save() {
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;

            const entryDate = moment(this.entryDate, 'YYYY-MM-DD').format('L');
            await this._leaderboards.updateEntry(this.id, entryDate, this.value, this.lineOfBusiness);
            this._notifier.success(this._i18n.tr('entry-update-success'));
            this.dialogController.ok({ deleted: false });
        } catch (err) {
            this._notifier.generalError();            
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteText = this._i18n.tr('delete');
    }

    async deleteEntry() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteText = this._i18n.tr('delete-confirm');
            return;
        }

        this.showDeleteConfirm = false;

        try {
            await this._leaderboards.deleteEntry(this.id);
            this._notifier.success('entry-update-deleted');
            this.dialogController.ok({ deleted: true });
        } catch (err) {
            this._notifier.generalError();
        }
    }
}

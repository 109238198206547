import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Api} from 'common/server';
import {c} from 'common/common';

@inject(EventAggregator, Api)
export class CorporateEventRegistrants {

	eventId = '';
	corporateEvent = null;
	reportName = '';
	reportType = '';
	registrants = [];
	reportComplete = false;
	isLoading = false;

	constructor(eventAggregator, api) {
		this.eventAggregator = eventAggregator;
		this.api = api;
	}

	activate(params, routerConfig) {
		this.eventId = params.id;
		this.reportType = params.type;
		if (!(this.reportType === 'registered' || this.reportType === 'non-registered')) this.reportType = 'registered';
		if (this.reportType === 'registered') this.reportName = 'Registered Report';
		else this.reportName = 'Non-Registered Report';
		this.loadEvent();
		this.loadRegistrants();
	}

	loadEvent() {
		var self = this;
		self.api.getData('event/corporate/' + self.eventId).then(data => {
			self.corporateEvent = data;
		});
	}

	loadRegistrants() {
	    var self = this;
	    self.isLoading = true;
		self.api.getData('event/corporate/' + self.eventId + '/' + self.reportType).then(data => {
		    self.reportComplete = true;
		    data.sort(function(a, b) {
		        if (a.lastName === b.lastName) return a.firstName.localeCompare(b.firstName);
		        return a.lastName.localeCompare(b.lastName);
		    });
			self.registrants = data;
		}).then(data => {
		    self.isLoading = false;
		});
	}

	showProfile(memberId) {
		this.eventAggregator.publish(c.EventKeys.site.openProfile, { member: null, memberId: memberId });
	}

}

import {inject} from 'aurelia-framework';
import {Api} from 'common/server';
import {Page} from 'common/ui';

@inject(Api, Page)
export class CorporateEvents {

	isLoaded = false;
	categories = [];

	constructor(api, page) {
		this.api = api;
		this.page = page;

		this.loadEvents();
	}

	activate() {
		this.page.track('members/events/corporate');
	}

	loadEvents() {
		var self = this;
		self.api.getData('event/corporate').then(data => {
			self.isLoaded = true;
			self.categories = data.categories;
		});
	}

}
import {inject} from 'aurelia-framework';
import {Page} from 'common/ui';
import moment from 'moment';
import 'moment-timezone';

@inject(Page)
export class Events {
    calendarUrl = '';

    constructor(page) {
        this.page = page;
        var tz = moment.tz.guess();
        this.calendarUrl = `https://calendar.google.com/calendar/embed?src=lpfncalendar%40gmail.com&ctz=${tz}&showTitle=0&showCalendars=0`;
    }

    activate() {
        this.page.track('members/events');
    }
}

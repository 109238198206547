import {inject} from 'aurelia-framework';
import {EventAggregator} from 'aurelia-event-aggregator';
import {Page} from 'common/ui';
import {Security} from 'common/security';

@inject(EventAggregator, Page, Security)
export class EventsNavbar {
    _security;

	eaSub = null;
	onEvents = true;
	onCorporateEvents = false;

	constructor(eventAggregator, page, security) {
		this.eventAggregator = eventAggregator;
		this.page = page;
		this._security = security;

		this.canAccessCorporateEvents = this._security.canAccessCorporateEvents();
	}

	attached() {
		var self = this;
		this.eaSub = this.eventAggregator.subscribe('router:navigation:complete', message => {
			self.setCurrentRouteValues();
		});
		var nav = $('.navbar-nav a[href="#/members/events"]');
		if (nav.length > 0) {
			nav.parent().addClass('active-custom');
		}
	}

	detached() {
		this.eaSub.dispose();
		var nav = $('.navbar-nav a[href="#/members/events"]');
		if (nav.length > 0) {
			nav.parent().removeClass('active-custom');
		}
	}

	setCurrentRouteValues() {
	    var currentRoute = this.page.getCurrentRoute();
		this.onEvents = currentRoute === '/members/events' || currentRoute === 'members/events';
		this.onCorporateEvents = currentRoute.indexOf('members/events/corporate') >= 0;
	}

} 

import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Calendar } from 'services/calendar';
import { Nylas } from 'services/nylas';

export class SchedulingPageEditor {
    static inject = [DialogController, NewInstance.of(ValidationController), Calendar, Nylas];
    dialogController;
    _calendar;
    _nylas;

    title;
    location;
    duration;
    description;
    calendarId;

    constructor(dialogController, validationController, calendar, nylas) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._calendar = calendar;
        this._nylas = nylas;

        ValidationRules
            .ensure('title').required()
            .ensure('location').required()
            .ensure('duration').required()
            .ensure('description').required()
            .ensure('calendarId').required()
            .on(this);
    }

    activate(model) {
        console.log(model);
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            await this._calendar.saveYourCalendarOnline(this.slug, saveModel);
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    close() {
        this.dialogController.cancel();
    }
}
